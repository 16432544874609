import React, { useState, useRef, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { enGB } from 'date-fns/locale';
import { Link } from 'react-router-dom';
// i18n
import { useTranslation } from 'react-i18next';
// @mui
import { Box, Button, Card, Modal, Stack, Typography } from '@mui/material';
// components
import { DateRangePicker } from 'react-date-range';
// form
import { useFormContext, Controller } from 'react-hook-form';
import { isAfter, differenceInDays, addDays, format, startOfDay, endOfDay, subDays, isSameDay } from 'date-fns';
import RHFDatePicker from '../RHFDatePicker';
import { DAYS_OF_WEEKS, STAY_STATUSES } from '../../../utils/constants';
// paths
import { PATH_RULES } from '../../../routes/paths';
// palette
import palette from '../../../../theme/palette';
// styles
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import './schedulerStyles.css';
import { determineEachDayOfInterval, isDateWithinInterval } from '../../../utils';

// ----------------------------------------------------------------------

RHFDateScheduler.propTypes = {
  name: PropTypes.string,
  schedulingDetails: PropTypes.object,
  userShares: PropTypes.number,
  stays: PropTypes.array,
  allowedRemainingNights: PropTypes.number,
  ownerId: PropTypes.string,
  isDatePickerDisplay: PropTypes.bool || undefined,
  isFirstSchedulingPeriod: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default function RHFDateScheduler({
  name,
  schedulingDetails,
  userShares,
  stays,
  allowedRemainingNights,
  ownerId,
  isDatePickerDisplay = false,
  isFirstSchedulingPeriod = false,
  disabled = false,
}) {
  const { t } = useTranslation();
  const modalRef = useRef(null);
  const { control, watch } = useFormContext();
  const [open, setOpen] = useState(false);
  const [availableDates, setAvailableDates] = useState([]);
  const [focusedRange, setFocusedRange] = useState([0, 0]);
  const selectedDate = watch(name);
  const yearStart = isFirstSchedulingPeriod
    ? startOfDay(new Date(schedulingDetails?.startDateForNextYear.seconds * 1000))
    : startOfDay(new Date(schedulingDetails?.startDateForCurrentYear.seconds * 1000));
  const yearEnd = isFirstSchedulingPeriod
    ? endOfDay(new Date(schedulingDetails?.endDateForNextYear.seconds * 1000))
    : endOfDay(new Date(schedulingDetails?.endDateForCurrentYear.seconds * 1000));
  const daysInYear = differenceInDays(yearEnd, yearStart);
  const currentDateIndex = isFirstSchedulingPeriod
    ? -1
    : Math.max(differenceInDays(startOfDay(new Date()), yearStart), -1);

  const toggle = () => setOpen(!open);

  const allocatedStayNights = schedulingDetails?.allocatedNightsPerShare * userShares;

  const highSeasonDates = schedulingDetails?.highSeasons?.map((season) => ({
    startDate: startOfDay(new Date(season?.startDate?.seconds * 1000)),
    endDate: endOfDay(new Date(season?.endDate?.seconds * 1000)),
  }));

  const coOwnerStays = stays?.filter(
    (stay) =>
      (stay?.createdByUserId !== ownerId && stay?.status === STAY_STATUSES.approved) ||
      stay?.status === STAY_STATUSES.pending
  );
  const ownerStays = stays?.filter((stay) => stay?.createdByUserId === ownerId);

  const numberOfShortStays =
    ownerStays?.filter(
      (stay) =>
        (stay?.status === STAY_STATUSES.approved || stay?.status === STAY_STATUSES.pending) &&
        stay?.scheduling?.isShortStay
    )?.length || 0;

  const minStayNights = isFirstSchedulingPeriod || numberOfShortStays < 2 ? 2 : schedulingDetails?.stayDuration;

  const isHighSeason = (date) =>
    highSeasonDates.some((range) =>
      isDateWithinInterval(date, {
        start: range.startDate,
        end: range.endDate,
      })
    );

  const existingHighSeasonDates = useMemo(
    () =>
      ownerStays.reduce((total, stay) => {
        if (!isHighSeason(new Date(stay?.scheduling?.startDate?.seconds * 1000))) {
          return total;
        }
        if (stay?.status === STAY_STATUSES.approved || stay?.status === STAY_STATUSES.pending) {
          return total + stay?.scheduling?.totalNights;
        }
        if (stay?.status === STAY_STATUSES.cancelled && stay?.cancellation?.isLateCancellation) {
          return total + countNonOverlappingDays(stay, coOwnerStays);
        }
        return total;
      }, 0),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ownerStays, coOwnerStays]
  );

  function daysUntilNextWeekday(date, targetDay) {
    const currentDay = date.getDay();
    const targetDayNumber = DAYS_OF_WEEKS[targetDay];

    if (targetDayNumber === undefined) {
      throw new Error('Invalid day of the week');
    }

    const daysUntilNext = (targetDayNumber - currentDay + 7) % 7;
    return daysUntilNext === 0 ? 7 : daysUntilNext;
  }

  const determineMaxNightStay = (startDate) => {
    const allowanceOrNightsRemainingToStartDate =
      allowedRemainingNights <= 8
        ? daysUntilNextWeekday(startDate, schedulingDetails?.stayStartDay)
        : allowedRemainingNights;

    if (isHighSeason(startDate)) {
      return allowedRemainingNights &&
        schedulingDetails?.stayNightsCapPerShareForHighSeason * userShares > allowedRemainingNights
        ? allowanceOrNightsRemainingToStartDate
        : schedulingDetails?.stayNightsCapPerShareForHighSeason * userShares - existingHighSeasonDates;
    }
    if (userShares === 1) {
      return allowedRemainingNights && schedulingDetails?.maxStayCapPerOneShare > allowedRemainingNights
        ? allowanceOrNightsRemainingToStartDate
        : schedulingDetails?.maxStayCapPerOneShare;
    }
    return allowedRemainingNights && schedulingDetails?.maxStayCapPerTwoPlusShare > allowedRemainingNights
      ? allowanceOrNightsRemainingToStartDate
      : schedulingDetails?.maxStayCapPerTwoPlusShare;
  };

  const countNonOverlappingDays = (target, ranges) => {
    // Generate each day in the target range
    const targetDays = determineEachDayOfInterval({
      start: startOfDay(new Date(target?.scheduling?.startDate?.seconds * 1000)),
      end: endOfDay(new Date(target?.scheduling?.endDate?.seconds * 1000)),
    });

    // Count days that do not overlap with any range in the array
    const nonOverlappingDays = targetDays.filter(
      (day) =>
        !ranges.some((range) =>
          isDateWithinInterval(day, {
            start: startOfDay(new Date(range?.scheduling?.startDate?.seconds * 1000)),
            end: endOfDay(new Date(range?.scheduling?.endDate?.seconds * 1000)),
          })
        )
    );

    return nonOverlappingDays.length;
  };

  const usedStays = ownerStays.reduce((total, stay) => {
    if (stay?.status === STAY_STATUSES.approved || stay?.status === STAY_STATUSES.pending) {
      return total + stay?.scheduling?.totalNights;
    }
    if (stay?.status === STAY_STATUSES.cancelled && stay?.cancellation?.isLateCancellation) {
      return total + countNonOverlappingDays(stay, coOwnerStays);
    }
    return total;
  }, 0);

  const getBlockedDates = () => {
    const unavailableDates = [];
    // Note: the start date and the end date of co-owner stays should be overlappable so departure and arrival can happen on the same day
    if (!isFirstSchedulingPeriod) {
      coOwnerStays.forEach((range) => {
        for (
          let d = addDays(startOfDay(new Date(range?.scheduling?.startDate?.seconds * 1000)), 1);
          d <= subDays(endOfDay(new Date(range?.scheduling?.endDate?.seconds * 1000)), 1);
          d = addDays(d, 1)
        ) {
          unavailableDates.push(d);
        }
      });
      ownerStays
        .filter((stay) => stay?.status === STAY_STATUSES.approved || stay?.status === STAY_STATUSES.pending)
        .forEach((range) => {
          // Can't stay before 7 nights after last stay
          for (
            let d = subDays(new Date(range?.scheduling?.startDate?.seconds * 1000), 6);
            d <= addDays(new Date(range?.scheduling?.endDate?.seconds * 1000), 6);
            d = addDays(d, 1)
          ) {
            unavailableDates.push(d);
          }
        });
    }
    return unavailableDates;
  };

  const blockedDates = getBlockedDates();

  const isDateBlocked = (date) =>
    blockedDates.some((blockedDate) => date.toDateString() === blockedDate.toDateString());

  const isStartDateAllowed = (date) => {
    const firstScheduleLastWeekSelection =
      (isFirstSchedulingPeriod && allowedRemainingNights) || !isFirstSchedulingPeriod || !allowedRemainingNights;
    const isStartDay = format(date, 'EEEE') === schedulingDetails?.stayStartDay;
    const isNotStartDayWithShortStaysAvailable =
      numberOfShortStays < 2 && format(date, 'EEEE') !== schedulingDetails?.stayStartDay;
    const isNotStartDayAfterCoOwnerShortStay =
      format(date, 'EEEE') !== schedulingDetails?.stayStartDay &&
      coOwnerStays?.some((blockedStay) => isSameDay(new Date(blockedStay.scheduling.endDate.seconds * 1000), date));

    return (
      isEndDateAllowed(date, addDays(date, minStayNights)) &&
      firstScheduleLastWeekSelection &&
      (isStartDay || isNotStartDayWithShortStaysAvailable || isNotStartDayAfterCoOwnerShortStay)
    );
  };

  const isEndDateAllowed = (startDate, date) => {
    const fullDurationDates = determineEachDayOfInterval({
      start: startDate,
      end: date,
    });

    const isShortStayDuringWeekEndingBeforeTheStartDay =
      numberOfShortStays < 2 &&
      format(startDate, 'EEEE') !== schedulingDetails?.stayStartDay &&
      !coOwnerStays?.some((blockedStay) => isSameDay(new Date(blockedStay.scheduling.endDate.seconds * 1000), date)) &&
      differenceInDays(endOfDay(date), startOfDay(startDate)) < schedulingDetails?.stayDuration;

    const isShortStayDurationFromStartDay =
      numberOfShortStays < 2 &&
      format(startDate, 'EEEE') === schedulingDetails?.stayStartDay &&
      format(date, 'EEEE') !== schedulingDetails?.stayStartDay &&
      differenceInDays(endOfDay(date), startOfDay(startDate)) < schedulingDetails?.stayDuration;

    const isNormalStayDurationFromStartDay =
      format(startDate, 'EEEE') === schedulingDetails?.stayStartDay &&
      format(date, 'EEEE') === schedulingDetails?.stayStartDay &&
      differenceInDays(endOfDay(date), startOfDay(startDate)) >= schedulingDetails?.stayDuration;

    const isFinishingExistingShortStay =
      coOwnerStays?.some((blockedStay) =>
        isSameDay(new Date(blockedStay.scheduling.endDate.seconds * 1000), startDate)
      ) && format(date, 'EEEE') === schedulingDetails?.stayStartDay;

    const isFirstSchedulingPeriodAndStartedNotOnStartDay =
      isFirstSchedulingPeriod &&
      format(startDate, 'EEEE') !== schedulingDetails?.stayStartDay &&
      (!allowedRemainingNights ||
        allowedRemainingNights > 8 ||
        daysUntilNextWeekday(startDate, schedulingDetails?.stayStartDay) !== 1) &&
      differenceInDays(endOfDay(date), startOfDay(startDate)) < schedulingDetails?.stayDuration;

    return (
      (isShortStayDuringWeekEndingBeforeTheStartDay ||
        isShortStayDurationFromStartDay ||
        isNormalStayDurationFromStartDay ||
        isFinishingExistingShortStay ||
        isFirstSchedulingPeriodAndStartedNotOnStartDay) &&
      !fullDurationDates.some(
        (d) => isDateBlocked(d) || isAfter(d, yearEnd) || (!isHighSeason(startDate) && isHighSeason(d))
      )
    );
  };

  const getAvailableDates = () => {
    const availableDates = [];
    if (!isFirstSchedulingPeriod && allocatedStayNights <= usedStays) {
      // Note: Can still book available days within 2 weeks with added cost
      for (let i = currentDateIndex + 1; i <= currentDateIndex + 14; i += 1) {
        const date = addDays(yearStart, i);
        if (isStartDateAllowed(date)) {
          availableDates.push(date);
        }
      }
    } else {
      for (let i = currentDateIndex + 1; i <= daysInYear; i += 1) {
        const date = addDays(yearStart, i);
        if (isStartDateAllowed(date)) {
          availableDates.push(date);
        }
      }
    }
    return availableDates;
  };

  const getAvailableEndDates = (startDate) => {
    const availableDates = [];
    for (let i = minStayNights; i <= determineMaxNightStay(startDate); i += 1) {
      const date = addDays(startDate, i);
      if (isEndDateAllowed(startDate, date)) {
        availableDates.push(date);
      }
    }

    return availableDates;
  };

  useEffect(() => {
    if (selectedDate && selectedDate?.startDate === selectedDate?.endDate) {
      setAvailableDates(getAvailableEndDates(selectedDate?.startDate));
    } else {
      setAvailableDates(getAvailableDates());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate, stays]);

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      toggle();

      if (selectedDate?.startDate === selectedDate?.endDate && selectedDate) {
        setAvailableDates(getAvailableDates());
      }
    }
  };

  const shouldDisableDate = (date) =>
    !availableDates.some((availableDate) => availableDate.toDateString() === date.toDateString());

  return (
    <>
      {isDatePickerDisplay ? (
        <>
          <Stack direction="row" spacing={2}>
            <Box onClick={toggle}>
              <RHFDatePicker
                value={selectedDate?.startDate ? selectedDate?.startDate : null}
                label={t('dashboard.scheduling.arrivalDate')}
                readOnly
              />
            </Box>
            <Box onClick={toggle}>
              <RHFDatePicker
                value={selectedDate?.endDate ? selectedDate?.endDate : null}
                label={t('dashboard.scheduling.departureDate')}
                readOnly
              />
            </Box>
          </Stack>
        </>
      ) : (
        <Box sx={{ display: 'flex', height: 145, cursor: disabled ? 'default' : 'pointer', gap: 1 }}>
          <Card
            sx={{ width: 290, padding: 1, display: 'flex', flexDirection: 'column' }}
            onClick={() => {
              if (!disabled) {
                toggle();
              }
            }}
          >
            <Box
              sx={{
                bgcolor: palette.light.error.light,
                borderRadius: 0.5,
                paddingX: 1,
                marginBottom: 0.5,
                color: palette.light.common.white,
              }}
            >
              {t('dashboard.scheduling.from')}
            </Box>
            <Box
              sx={{
                paddingX: 1,
                paddingY: 0.5,
              }}
            >
              {selectedDate?.startDate ? format(selectedDate?.startDate, 'EEEE') : t('dashboard.scheduling.select')}
            </Box>
            <Box
              sx={{
                paddingX: 1,
                paddingY: 0.5,
              }}
            >
              {selectedDate?.startDate
                ? format(selectedDate?.startDate, 'dd MMMM yyyy')
                : t('dashboard.scheduling.startDate')}
            </Box>
            <Box
              sx={{
                paddingX: 1,
                paddingY: 0.5,
              }}
            >
              {schedulingDetails?.checkInTime &&
                selectedDate?.endDate &&
                format(new Date(schedulingDetails?.checkInTime.seconds * 1000), 'HH:mm  a')}
            </Box>
          </Card>
          <Card
            sx={{ width: 290, padding: 1, display: 'flex', flexDirection: 'column' }}
            onClick={() => {
              if (!disabled) {
                toggle();
              }
            }}
          >
            <Box
              sx={{
                bgcolor: palette.light.error.light,
                borderRadius: 0.5,
                paddingX: 1,
                marginBottom: 0.5,
                color: palette.light.common.white,
              }}
            >
              {t('dashboard.scheduling.to')}
            </Box>
            <Box
              sx={{
                paddingX: 1,
                paddingY: 0.5,
              }}
            >
              {selectedDate?.endDate ? format(selectedDate?.endDate, 'EEEE') : t('dashboard.scheduling.select')}
            </Box>
            <Box
              sx={{
                paddingX: 1,
                paddingY: 0.5,
              }}
            >
              {selectedDate?.endDate
                ? format(selectedDate?.endDate, 'dd MMMM yyyy')
                : t('dashboard.scheduling.endDate')}
            </Box>
            <Box
              sx={{
                paddingX: 1,
                paddingY: 0.5,
              }}
            >
              {schedulingDetails?.checkOutTime &&
                selectedDate?.endDate &&
                format(new Date(schedulingDetails?.checkOutTime.seconds * 1000), 'HH:mm a')}
            </Box>
          </Card>
        </Box>
      )}
      <Modal
        open={open}
        onClose={toggle}
        aria-labelledby="date-picker-modal-title"
        aria-describedby="date-picker-modal-description"
        onClick={handleClickOutside}
      >
        <Box
          ref={modalRef}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            outline: 0,
            borderRadius: 2,
          }}
          className="customDateRangePicker"
        >
          <Controller
            name={name}
            control={control}
            defaultValue={{
              startDate: isFirstSchedulingPeriod
                ? new Date(schedulingDetails?.startDateForNextYear.seconds * 1000)
                : new Date(),
              endDate: isFirstSchedulingPeriod
                ? new Date(schedulingDetails?.startDateForNextYear.seconds * 1000)
                : new Date(),
              key: 'selection',
            }}
            render={({ field: { onChange, value } }) => (
              <>
                <DateRangePicker
                  renderStaticRangeLabel={(label) => label}
                  onChange={(item) => onChange(item.selection)}
                  focusedRange={focusedRange}
                  onRangeFocusChange={setFocusedRange}
                  moveRangeOnFirstSelection={false}
                  ranges={[value]}
                  locale={enGB}
                  inputRanges={[]}
                  staticRanges={[]}
                  rangeColors={[palette.light.primary.main]}
                  disabledDay={shouldDisableDate}
                  showDateDisplay={false}
                  fixedHeight
                  months={2}
                  direction="horizontal"
                />
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                  <Link to={PATH_RULES.scheduling} target="_blank" style={{ textDecoration: 'none' }}>
                    <Button variant="text">
                      <Typography color="#0F4646">{t('common.schedulingRules')}</Typography>
                    </Button>
                  </Link>
                  <Box>
                    <Button
                      variant="text"
                      onClick={() => {
                        setFocusedRange([0, 0]);
                        onChange({
                          startDate: isFirstSchedulingPeriod
                            ? new Date(schedulingDetails?.startDateForNextYear.seconds * 1000)
                            : new Date(),
                          endDate: isFirstSchedulingPeriod
                            ? new Date(schedulingDetails?.startDateForNextYear.seconds * 1000)
                            : new Date(),
                          key: 'selection',
                        });
                      }}
                    >
                      <Typography color="#0F4646">{t('common.clear')}</Typography>
                    </Button>
                    <Button variant="text" onClick={toggle}>
                      <Typography color="#0F4646">{t('common.ok')}</Typography>
                    </Button>
                  </Box>
                </Box>
              </>
            )}
          />
        </Box>
      </Modal>
    </>
  );
}
