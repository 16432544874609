import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// @mui
import { Box, styled } from '@mui/material';

// ----------------------------------------------------------------------

const CarouselStyle = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
}));

const IndicatorStyle = styled('div')(({ theme }) => ({
  cursor: 'pointer',
  width: 36,
  height: 8,
  borderRadius: 8,
  backgroundColor: theme.palette.primary.main,
  transition: 'opacity 0.3s',
}));

// ----------------------------------------------------------------------

Carousel.propTypes = {
  images: PropTypes.array.isRequired,
  imageStyles: PropTypes.object,
  intervalTime: PropTypes.number,
};

export default function Carousel({ images, imageStyles, intervalTime = 7000 }) {
  const [currentImage, setCurrentImage] = useState(0);

  const handleIndicatorClick = (index) => {
    setCurrentImage(index);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % images.length);
    }, intervalTime);

    return () => {
      clearInterval(interval);
    };
  }, [images.length, intervalTime]);

  return (
    <Box>
      <CarouselStyle>
        {images.map((image, index) => (
          <img
            key={index}
            src={image.src}
            style={{ ...imageStyles, display: index === currentImage ? 'block' : 'none' }}
            alt={image.alt}
          />
        ))}
        <Box
          sx={{
            position: 'absolute',
            bottom: -20,
            display: 'flex',
            justifyContent: 'center',
            gap: 1,
          }}
        >
          {images.map((_, index) => (
            <IndicatorStyle
              key={index}
              onClick={() => handleIndicatorClick(index)}
              style={{ opacity: index === currentImage ? 1 : 0.3 }}
            />
          ))}
        </Box>
      </CarouselStyle>
    </Box>
  );
}
