// i18n
import { useTranslation } from 'react-i18next';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Stack, Container, Typography, Grid } from '@mui/material';
// components
import { Page } from '../../../shared/components/base';
import { LoginForm } from './components';
// images
import authImage from '../../../assets/images/auth/auth_image.png';

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
  '@media  (max-width: 900px)': {
    padding: theme.spacing(12, 4),
  },
}));

// ----------------------------------------------------------------------

function Login() {
  const { t } = useTranslation();

  return (
    <Page title={t('login.login')}>
      <RootStyle>
        <Container maxWidth={false} disableGutters>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  display: { xs: 'none', md: 'flex' },
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100vh',
                  bgcolor: 'black',
                  boxShadow: (theme) => theme.customShadows.secondary,
                }}
              >
                <img src={authImage} style={{ width: '100%', height: '100%', objectFit: 'cover' }} alt="Description" />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <ContentStyle>
                <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
                  <Box sx={{ flexGrow: 1 }}>
                    <Typography variant="h3" gutterBottom>
                      {t('login.title')}
                    </Typography>
                    <Typography sx={{ color: 'text.secondary' }}>{t('login.subtitle')}</Typography>
                  </Box>
                </Stack>
                <LoginForm />
              </ContentStyle>
            </Grid>
          </Grid>
        </Container>
      </RootStyle>
    </Page>
  );
}

export default Login;
