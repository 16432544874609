function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_SETTINGS = '/settings';
const MY_HOME = '/my-home';
const ROOTS_RULES = '/rules';

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  setPassword: path(ROOTS_AUTH, '/set-password'),
};

export const PATH_PAGE = {
  page403: '/403',
  page404: '/404',
  page500: '/500',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    schedule: (id) => path(ROOTS_DASHBOARD, `/schedule/${id}`),
    firstSchedule: (id) => path(ROOTS_DASHBOARD, `/first-schedule/${id}`),
    details: path(ROOTS_DASHBOARD, '/details'),
  },
  permissionDenied: path(ROOTS_DASHBOARD, '/permission-denied'),
};

export const PATH_MY_HOME = {
  root: MY_HOME,
};

export const PATH_SETTINGS = {
  root: ROOTS_SETTINGS,
  myProfile: path(ROOTS_SETTINGS, '/my-profile'),
};

export const PATH_RULES = {
  root: ROOTS_RULES,
  scheduling: path(ROOTS_RULES, '/scheduling'),
};
