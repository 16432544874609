import { eachDayOfInterval } from 'date-fns';

const determineEachDayOfInterval = (range) => {
  // Return false if end is before start
  if (!range?.start || !range?.end || range?.end < range?.start) {
    console.log('Invalid Date Interval');
    return [];
  }

  return eachDayOfInterval({ start: range?.start, end: range?.end });
};

export default determineEachDayOfInterval;
