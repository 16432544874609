import PropTypes from 'prop-types';
// @mui
import { AppBar, Container, Box, useTheme, Hidden } from '@mui/material';
// state
import { useAtom } from 'jotai';
import { userRecordAtom } from '../../../../shared/state/atoms';
// components
import { NavSectionBurgerMenu, NavSectionHorizontal } from '../../../../shared/components/nav-section';
import { Logo } from '../../../../shared/components/base';
import navConfig from './NavConfig';

// ----------------------------------------------------------------------

Header.propTypes = {
  ToolbarStyle: PropTypes.object,
};

export default function Header({ ToolbarStyle }) {
  const theme = useTheme();
  const [userRecord] = useAtom(userRecordAtom);

  return (
    <AppBar sx={{ boxShadow: theme.customShadows.secondary }}>
      <ToolbarStyle disableGutters>
        <Container
          sx={{
            display: 'flex',
            alignItems: 'center',
            minWidth: '100%',
          }}
        >
          <Logo />
          <Box sx={{ flexGrow: 1 }} />
          <Hidden smDown>
            {userRecord?.onboardingComplete && <NavSectionHorizontal navConfig={navConfig} />}
          </Hidden>
          <Hidden smUp>
            {userRecord?.onboardingComplete && <NavSectionBurgerMenu navConfig={navConfig} />}
          </Hidden>
        </Container>
      </ToolbarStyle>
    </AppBar>
  );
}
