import PropTypes from 'prop-types';
import { memo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// components
import { IconButton, Stack, Menu, MenuItem, Box, Typography } from '@mui/material';
import { Iconify } from '../../base';
import { ICON } from '../../../../app/config';

// ----------------------------------------------------------------------

NavSectionBurgerMenu.propTypes = {
  navConfig: PropTypes.array,
};

function NavSectionBurgerMenu({ navConfig }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNavigation = (path) => {
    handleClose();
    navigate(path);
  }

  return (
    <>
      <Stack direction="row" justifyContent="center" sx={{ borderRadius: 1, px: 0.5 }}>
        <IconButton onClick={handleOpen}>
          <Iconify icon="iconamoon:menu-burger-horizontal-duotone" width={24} height={24} color="white" />
        </IconButton>
      </Stack>
      <Menu
        PaperProps={{
          sx: {
            backgroundColor: 'primary.main',
          },
        }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {navConfig.map((item, index) => (
          <Stack key={index} direction="row" flexShrink={0}>
            {item.items.map((list) => (
              <MenuItem key={list.title} onClick={() => handleNavigation(list.path)}>
                <Box
                  component="span"
                  sx={{
                    alignSelf: 'center',
                    mr: 1,
                    width: ICON.NAVBAR_ITEM_HORIZONTAL,
                    height: ICON.NAVBAR_ITEM_HORIZONTAL,
                    '& svg': { width: '100%', height: '100%' },
                  }}
                >
                  {list.icon}
                </Box>
                <Typography variant="body2" sx={{ color: 'white' }}>
                  {t(list.title).toUpperCase()}
                </Typography>
              </MenuItem>
            ))}
          </Stack>
        ))}
      </Menu>
    </>
  );
}

export default memo(NavSectionBurgerMenu);
