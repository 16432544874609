import { pxToRem, responsiveFontSizes } from '../utils/getFontValue';

import palette from './palette';

// ----------------------------------------------------------------------

// const FONT_PRIMARY = 'Noto Sans, sans-serif'; // Google Font
const FONT_PRIMARY = 'Roboto, sans-serif'; // Google Font
const FONT_SECONDARY = 'Noto Serif, sans-serif'; // Google Font
const FONT_TERTIARY = 'Roboto, sans-serif'; // Google Font

const typography = {
  fontFamily: FONT_PRIMARY,
  fontWeightRegular: 400,
  fontWeightMedium: 600,
  fontWeightBold: 700,
  h1: {
    fontWeight: 700,
    lineHeight: 80 / 64,
    fontSize: pxToRem(40),
    letterSpacing: 2,
    ...responsiveFontSizes({ sm: 52, md: 58, lg: 64 }),
  },
  h2: {
    fontWeight: 700,
    lineHeight: 64 / 48,
    fontSize: pxToRem(32),
    ...responsiveFontSizes({ sm: 40, md: 44, lg: 48 }),
  },
  h3: {
    fontWeight: 400,
    lineHeight: 1.5,
    fontSize: pxToRem(24),
    color: palette.light.primary.main,
    ...responsiveFontSizes({ sm: 26, md: 30, lg: 32 }),
  },
  h4: {
    fontWeight: 400,
    lineHeight: 1.5,
    fontSize: pxToRem(20),
    color: palette.light.primary.main,
    ...responsiveFontSizes({ sm: 20, md: 24, lg: 24 }),
  },
  h5: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(18),
    ...responsiveFontSizes({ sm: 19, md: 20, lg: 20 }),
  },
  h6: {
    fontWeight: 700,
    lineHeight: 28 / 18,
    fontSize: pxToRem(17),
    ...responsiveFontSizes({ sm: 18, md: 18, lg: 18 }),
  },
  subtitle1: {
    fontWeight: 400,
    lineHeight: 1.5,
    color: palette.light.grey[600],
    fontSize: pxToRem(16),
  },
  subtitle2: {
    fontWeight: 600,
    lineHeight: 22 / 14,
    fontSize: pxToRem(14),
  },
  subtitle3: {
    fontWeight: 400,
    lineHeight: 22 / 14,
    fontSize: pxToRem(20),
  },
  body1: {
    lineHeight: 1.5,
    fontSize: pxToRem(16),
  },
  body2: {
    fontWeight: 400,
    lineHeight: 22 / 14,
    fontSize: pxToRem(14),
  },
  caption: {
    fontWeight: 400,
    lineHeight: 1.5,
    fontSize: pxToRem(12.5),
    color: palette.light.common.white,
  },
  overline: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(12),
    textTransform: 'uppercase',
  },
  button: {
    fontWeight: 700,
    lineHeight: 24 / 14,
    fontSize: pxToRem(14),
    textTransform: 'capitalize',
  },
  secondaryButton: {
    fontWeight: 400,
    lineHeight: pxToRem(24),
    fontSize: pxToRem(16),
    fontFamily: FONT_SECONDARY,
  },
  navItem: {
    fontWeight: 400,
    lineHeight: 17,
    fontSize: pxToRem(15),
    fontFamily: FONT_TERTIARY,
    textTransform: 'uppercase',
  },
  pageTitle: {
    fontWeight: 500,
    lineHeight: pxToRem(36),
    fontSize: pxToRem(24),
    fontFamily: FONT_SECONDARY,
    color: palette.light.primary.main,
    ...responsiveFontSizes({ sm: 20, md: 24, lg: 24 }),
  },
  noticeTitle: {
    fontWeight: 500,
    lineHeight: 1.5,
    fontFamily: FONT_TERTIARY,
    color: palette.light.primary.main,
    fontSize: pxToRem(16),
  },
  noticeSubtitle: {
    fontWeight: 400,
    lineHeight: 1.5,
    fontFamily: FONT_TERTIARY,
    color: palette.light.primary.main,
    fontSize: pxToRem(12),
  },
  buttonTitle: {
    fobntFamily: FONT_TERTIARY,
    textTransform: 'capitalize',
  },
  sideText: {
    fontWeight: 500,
    lineHeight: 1.5,
    fontSize: pxToRem(14),
    fontFamily: FONT_PRIMARY,
  },
  successHeader: {
    fontSize: pxToRem(50),
    fontWeight: 500,
    fontFamily: FONT_TERTIARY,
    lineHeight: 1.5,
  },
  successSubtitle: {
    fontSize: pxToRem(16),
    fontWeight: 500,
    fontFamily: FONT_PRIMARY,
    lineHeight: 1.5,
  },
  whiteBody: {
    fontSize: pxToRem(16),
    fontWeight: 600,
    fontFamily: FONT_TERTIARY,
    color: palette.light.common.white,
  },
  whiteBody2: {
    fontSize: pxToRem(14),
    fontWeight: 400,
    fontFamily: FONT_TERTIARY,
    color: palette.light.common.white,
  }
};

export default typography;
