import React from 'react';
import firebase from 'firebase';
import { useAtom } from 'jotai';
import { auth } from './firebase';
import { userAtom, userRecordAtom } from '../state/atoms';
import { firebaseErrorCodes } from '../utils/constants';

function useAuthService() {
  const [, setUser] = useAtom(userAtom);
  const [, setUserRecord] = useAtom(userRecordAtom);

  React.useEffect(() => auth().onAuthStateChanged((user) => setUser(user)), [setUser]);

  const createUserWithEmailAndPassword = (email, password) =>
    auth()
      .createUserWithEmailAndPassword(email, password)
      .catch((err) => {
        switch (err.code) {
          case firebaseErrorCodes.EMAIL_IN_USER:
            break;
          default:
            break;
        }
      });

  const signIn = (email, password) =>
    firebase
      .auth()
      .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
      // eslint-disable-next-line arrow-body-style
      .then(() => {
        return auth()
          .signInWithEmailAndPassword(email, password)
          .then((res) => res);
      });

  const setNewPassword = (password) =>
    auth()
      .currentUser.updatePassword(password)
      .then((res) => res);

  const resetPassword = (email) =>
    auth()
      .sendPasswordResetEmail(email)
      .then((res) => res);

  function signOut() {
    setUser(null);
    setUserRecord(null);
    return auth().signOut();
  }

  return { createUserWithEmailAndPassword, signIn, setNewPassword, resetPassword, signOut };
}

export default useAuthService;
