import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// locales
import en from './locales/en.json';
import fr from './locales/fr.json';
import de from './locales/de.json';

i18n.use(initReactI18next).init({
  resources: { en, fr, de },
  lng: 'en',
});

export default i18n;
