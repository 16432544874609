import React from 'react';
import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import { Toolbar } from '@mui/material';
// config
import { HEADER } from '../../../config';
// components
import AuthHeader from './authHeader';
import Header from './Header';

// ----------------------------------------------------------------------

MainHeader.propTypes = {
  isAuth: PropTypes.bool,
};

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  height: HEADER.MOBILE_HEIGHT,
  transition: theme.transitions.create(['height', 'background-color'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up('md')]: {
    height: HEADER.MAIN_DESKTOP_HEIGHT,
  },
}));

// ----------------------------------------------------------------------

function MainHeader({ isAuth }) {
  return isAuth ? <AuthHeader ToolbarStyle={ToolbarStyle} /> : <Header ToolbarStyle={ToolbarStyle} />;
}

export default MainHeader;
