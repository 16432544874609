import PropTypes from 'prop-types';
// @mui
import { Box, styled, Typography } from '@mui/material';

// ----------------------------------------------------------------------

const RootStyle = styled(Box)(({ theme }) => ({
  height: 'auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

// ----------------------------------------------------------------------

PageTitle.propTypes = {
  title: PropTypes.string,
};

export default function PageTitle({ title }) {
  return (
    <RootStyle>
      <Typography variant="pageTitle" sx={{ textAlign: 'center', my: 2 }}>
        {title}
      </Typography>
    </RootStyle>
  );
}
