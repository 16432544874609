import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// components
import { TextField } from '@mui/material';
import { TimePicker } from '@mui/lab';

// ----------------------------------------------------------------------

RHFTimePicker.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
};

export default function RHFTimePicker({ name, label, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={new Date()}
      render={({ field: { onChange, value } }) => (
        <TimePicker
          label={label}
          value={value}
          onChange={onChange}
          inputFormat="HH:mm"
          renderInput={(params) => <TextField {...params} fullWidth />}
          {...other}
        />
      )}
    />
  );
}
