import { useState } from 'react';
import PropTypes from 'prop-types';
// @mui
import { AppBar, Container, Select, MenuItem, Box } from '@mui/material';
// components
import { Logo } from '../../../../shared/components/base';
// constants
import { AUTH_LANGUAGE_OPTIONS } from '../../../../shared/utils/constants';

// ----------------------------------------------------------------------

AuthHeader.propTypes = {
  ToolbarStyle: PropTypes.object,
};

export default function AuthHeader({ ToolbarStyle }) {
  const [selectedLanguage, setSelectedLanguage] = useState('english');

  return (
    <AppBar sx={{ boxShadow: (theme) => theme.customShadows.secondary }}>
      <ToolbarStyle disableGutters>
        <Container
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            minWidth: '100%',
          }}
        >
          <Logo />
          <Box sx={{ position: 'absolute', right: '1.5rem' }}>
            <Select
              variant="outlined"
              value={selectedLanguage}
              onChange={(e) => setSelectedLanguage(e.target.value)}
            >
              {AUTH_LANGUAGE_OPTIONS.map((language) => (
                <MenuItem key={language.value} value={language.value}>
                  {language.label}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Container>
      </ToolbarStyle>
    </AppBar>
  );
}
