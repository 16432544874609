import firebase from 'firebase';
import { useTheme } from '@mui/material';
import dayjs from 'dayjs';
import { db } from './firebase';
import { firestoreKeys } from '../utils/constants';

export default function useMailService() {
  const theme = useTheme();

  const getLogoImage = async () => firebase.storage().ref().child(`${firestoreKeys.ASSETS}/logo.png`).getDownloadURL();

  const sendEmail = (data) => {
    db.collection(firestoreKeys.MAIL).add(data);
  };

  // Doc other email notifications template 1
  const sendStaySchedulingEmail = async (user, associatedUsers, propertyManagerEmails, home, stay, isShortStay) => {
    const natureOfRequest = isShortStay ? ' short stay' : 'n extension beyond original nights ';

    sendEmail({
      message: {
        subject: `Confirmation of your stay at your ${home?.location?.address}, ${home?.location?.country} home`,
        text: ``,
        html: `
           <div style="background-color: ${theme.palette.primary.main
          }; padding: 10px; height: 30px; display: flex; justify-content: center; align-items: center;">
            <a href="http://localhost:3000" style="display: flex; justify-content: center; align-items: center;">
              <img src="${await getLogoImage()}" alt="Logo" style="width: 100px; height: auto;" />
            </a>
          </div>
        <h1 style="font-family: 'Roboto', sans-serif; color: black;">Dear ${user?.details?.firstName} ${user?.details?.lastName
          }</h1>
        </br>
        <div style="font-family: 'Roboto', sans-serif; color: black;">
                <p style="color: black">We received your request for a${natureOfRequest} in your ${home?.location?.address}, ${home?.location?.country} home.</p>
                  <ul>
                  <li>From: ${dayjs(stay?.scheduleDates?.startDate).format('DD MMMM YYYY')}</li>
                  <li>To: ${dayjs(stay?.scheduleDates?.endDate).format('DD MMMM YYYY')}</li>
                  <li>Stay type: ${stay?.stayType}</li>
                  ${stay?.guestName ?
            `<li>Guest Details:
                      <ul>
                        <li>Name: ${stay?.guestName}</li>
                        <li>Email: ${stay?.guestEmail}</li>
                        <li>Phone Number: ${stay?.phoneNumber}</li>
                      </ul>
                    </li>` : ''
          }
                  <li>Guests: ${stay?.guests}</li>
                  ${stay?.additionalRequest && `<li>Requests: ${stay?.additionalRequest}</li>`}
                  ${stay?.roomSelections && `<li>Bedrooms:
                    <ul>
                      ${stay?.roomSelections?.map(
            (room) => `
                          <li>Name: ${room?.name}</li>
                          <li>Preparation: ${room?.selectedValue}</li>
                        `
          ).join('')}
                    </ul>
                  </li>`}
                  </ul>
                  <p style="color: black">
                  If you have any questions or require assistance, please feel free to contact us.
                </div>
      `,
      },
      to: [user?.contact?.emailAddress, ...associatedUsers.map((user) => user?.contact?.emailAddress), ...propertyManagerEmails],
    });
  };

  // Doc other email notifications template 2
  const sendRentalStaySchedulingEmail = async (user, associatedUsers, home, stay, isShortStay) => {
    const natureOfRequest = isShortStay ? ' short stay' : 'n extension beyond original nights ';

    sendEmail({
      message: {
        subject: `Request to rent out nights in your ${home?.location?.address}, ${home?.location?.country} home`,
        text: ``,
        html: `
          <div style="background-color: ${theme.palette.primary.main
          }; padding: 10px; height: 30px; display: flex; justify-content: center; align-items: center;">
            <a href="http://localhost:3000" style="display: flex; justify-content: center; align-items: center;">
              <img src="${await getLogoImage()}" alt="Logo" style="width: 100px; height: auto;" />
            </a>
          </div>
          <h1 style="font-family: 'Roboto', sans-serif; color: black;">Dear ${user?.details?.firstName} ${user?.details?.lastName
          }</h1>
          </br>
          <div style="font-family: 'Roboto', sans-serif; color: black;">
            <p style="color: black">We recieved your request for a${natureOfRequest} in your ${home?.location?.address}, ${home?.location?.country
          } home.</p>
              <ul>
                <li>From: ${dayjs(stay?.scheduleDates?.startDate).format('DD MMMM YYYY')}</li>
                <li>To: ${dayjs(stay?.scheduleDates?.endDate).format('DD MMMM YYYY')}</li>
              </ul>
              <p style="color: black">
                We will proceed with advertising your nights for rental and will keep you informed about the outcome.
              </p>
              <p>
                With kind regards\nThe Lazazu team
              </p>
          </div>
        `,
      },
      to: [user?.contact?.emailAddress, ...associatedUsers.map((user) => user?.contact?.emailAddress)],
    });
  };

  // Doc other email notifications template 4
  const sendStayUpdatedEmail = async (user, associatedUsers, home, stay) => {
    sendEmail({
      message: {
        subject: `Your stay details at your ${home?.location?.address}, ${home?.location?.country} home were updated`,
        text: ``,
        html: `
          <div style="background-color: ${theme.palette.primary.main
          }; padding: 10px; height: 30px; display: flex; justify-content: center; align-items: center;">
            <a href="http://localhost:3000" style="display: flex; justify-content: center; align-items: center;">
              <img src="${await getLogoImage()}" alt="Logo" style="width: 100px; height: auto;" />
            </a>
          </div>
          <h1 style="font-family: 'Roboto', sans-serif; color: black;">Dear ${user?.details?.firstName} ${user?.details?.lastName
          }</h1>
          </br>
          <div style="font-family: 'Roboto', sans-serif; color: black;">
            <p style="color: black">Your stay at your home in ${home?.location?.address}, ${home?.location?.country
          } was successfully amended. Here are the new details.</p>
            <ul>
                <li>From: ${dayjs(stay?.scheduleDates?.startDate).format('DD MMMM YYYY')}</li>
                <li>To: ${dayjs(stay?.scheduleDates?.endDate).format('DD MMMM YYYY')}</li>
                <li>Stay type: ${stay?.stayType}</li>
                ${stay?.guestName &&
          `<li>Guest Details:
                    <ul>
                      <li>Name: ${stay?.guestName}</li>
                      <li>Email: ${stay?.guestEmail}</li>
                      <li>Phone Number: ${stay?.phoneNumber}</li>
                    </ul>
                  </li>`
          }
          ${stay?.roomSelections && `<li>Bedrooms:
            <ul>
              ${stay?.roomSelections?.map(
            (room) => `
                  <li>Name: ${room?.name}</li>
                  <li>Preparation: ${room?.selectedValue}</li>
                `
          ).join('')}
            </ul>
          </li>`}
                <li>Guests: ${stay?.guests}</li>
                ${stay?.additionalRequest && `<li>Requests: {stay?.additionalRequest}</li>`}
              </ul>  
            <p style="color: black">
                If you have any questions or require assistance, please don't hesitate to contact us.
              </p>
              <p>
                With kind regards\nThe Lazazu team
              </p>
          </div>
        `,
      },
      to: [user?.contact?.emailAddress, ...associatedUsers.map((user) => user?.contact?.emailAddress)],
    });
  };

  // Doc other email notifications template 6
  const sendStayCancelledByUserEmail = async (user, associatedUsers, propertyManagerEmails, home, stay) => {
    console.log([user?.contact?.emailAddress, ...associatedUsers.map((user) => user?.contact?.emailAddress), ...propertyManagerEmails])
    sendEmail({
      message: {
        subject: `Your stay details at your ${home?.location?.address}, ${home?.location?.country} home was cancelled`,
        text: ``,
        html: `
        <div style="background-color: ${theme.palette.primary.main
          }; padding: 10px; height: 30px; display: flex; justify-content: center; align-items: center;">
            <a href="http://localhost:3000" style="display: flex; justify-content: center; align-items: center;">
              <img src="${await getLogoImage()}" alt="Logo" style="width: 100px; height: auto;" />
            </a>
          </div>
        <h1 style="font-family: 'Roboto', sans-serif; color: black;">Dear ${user?.details?.firstName} ${user?.details?.lastName
          }</h1>
        </br>
        <div style="font-family: 'Roboto', sans-serif; color: black;">
          <p style="color: black">Your stay at your home in ${home?.location?.address}, ${home?.location?.country
          } was cancelled</p>
              <ul>
                <li>From: ${dayjs(stay?.scheduleDates?.startDate).format('DD MMMM YYYY')}</li>
                <li>To: ${dayjs(stay?.scheduleDates?.endDate).format('DD MMMM YYYY')}</li>
              </ul>
            <p style="color: black">
              If you have any questions or require assistance, please don't hesitate to contact us.
            </p>
            <p>
              With kind regards\nThe Lazazu team
            </p>
        </div>
      `,
      },
      to: [user?.contact?.emailAddress, ...associatedUsers.map((user) => user?.contact?.emailAddress), ...propertyManagerEmails],
    });
  };

  // Doc other email notifications template 8
  const sendDaysOverAllocationStayEmail = async (user, associatedUsers, home, stay) => {
    sendEmail({
      message: {
        subject: `Stay request for your ${home?.location?.address}, ${home?.location?.country} home received`,
        text: ``,
        html: `
        <div style="background-color: ${theme.palette.primary.main
          }; padding: 10px; height: 30px; display: flex; justify-content: center; align-items: center;">
            <a href="http://localhost:3000" style="display: flex; justify-content: center; align-items: center;">
              <img src="${await getLogoImage()}" alt="Logo" style="width: 100px; height: auto;" />
            </a>
          </div>
        <h1 style="font-family: 'Roboto', sans-serif; color: black;">Dear ${user?.details?.firstName} ${user?.details?.lastName
          }</h1>
        </br>
        <div style="font-family: 'Roboto', sans-serif; color: black;">
          <p style="color: black">We received your request for a stay beyond the original nights allocation at ${home?.location?.address
          }, ${home?.location?.country} home.</p>
          <p style="color: black">Here are the details of your request:</p>
              <ul>
                <li>From: ${dayjs(stay?.scheduleDates?.startDate).format('DD MMMM YYYY')}</li>
                <li>To: ${dayjs(stay?.scheduleDates?.endDate).format('DD MMMM YYYY')}</li>
                <li>Guests: ${stay?.guests}</li>
                <li>Status: Pending Approval</li>
              </ul>
          <p style="color: black">
            We will review your request and let you know if it is approved.
          </p>
          <p style="color: black">
            If you have any questions or require assistance, please don't hesitate to contact us.
          </p>
          <p>
            With kind regards\nThe Lazazu team
          </p>
        </div>
      `,
      },
      to: [user?.contact?.emailAddress, ...associatedUsers.map((user) => user?.contact?.emailAddress)],
    });
  };

  // Doc other email notifications template 10
  const sendFirstSchedulingPeriodSubmissionEmail = async (user, associatedUsers, home, preferences) => {
    const firstSelection = preferences.filter(pref => pref.scheduling.priority === 'First');
    const secondSelection = preferences.filter(pref => pref.scheduling.priority === 'Second');

    sendEmail({
      message: {
        subject: `We received your preferred dates for your stays in your ${home?.location?.address}, ${home?.location?.country} home`,
        text: ``,
        html: `
        <div style="background-color: ${theme.palette.primary.main
          }; padding: 10px; height: 30px; display: flex; justify-content: center; align-items: center;">
            <a href="http://localhost:3000" style="display: flex; justify-content: center; align-items: center;">
              <img src="${await getLogoImage()}" alt="Logo" style="width: 100px; height: auto;" />
            </a>
          </div>
        <h1 style="font-family: 'Roboto', sans-serif; color: black;">Dear ${user?.details?.firstName} ${user?.details?.lastName
          }</h1>
        </br>
        <div style="font-family: 'Roboto', sans-serif; color: black;">
          <p style="color: black">Thank you for submitting your preferred dates for your stays at your ${home?.location?.address}, ${home?.location?.country} home in the
           upcoming scheduling year. We will review and confirm your stays shortly.
          </p>
          <p style="color: black">Submitted dates:</p>
          <p style="color: black">Priority 1:</p>
              <ul>
               ${firstSelection.map((pref, index) => `<li>Week ${index + 1}: ${dayjs(pref?.scheduling?.startDate).format('DD MMMM YYYY')} - ${dayjs(pref?.scheduling?.endDate).format('DD MMMM YYYY')}</li>`).join('')}
              </ul>
          <p style="color: black">Priority 2:</p>
              <ul>
              ${secondSelection.map((pref, index) => `<li>Week ${index + 1}: ${dayjs(pref?.scheduling?.startDate).format('DD MMMM YYYY')} - ${dayjs(pref?.scheduling?.endDate).format('DD MMMM YYYY')}</li>`).join('')}
              </ul>
          <p style="color: black">
            Should you have any questions in the meantime, please feel free to contact us.
          </p>
          <p>
            With kind regards\nThe Lazazu team
          </p>
        </div>
      `,
      },
      to: [user?.contact?.emailAddress, ...associatedUsers.map((user) => user?.contact?.emailAddress)],
    });
  };

  return {
    sendStaySchedulingEmail,
    sendRentalStaySchedulingEmail,
    sendStayUpdatedEmail,
    sendStayCancelledByUserEmail,
    sendDaysOverAllocationStayEmail,
    sendFirstSchedulingPeriodSubmissionEmail,
  };
}
