export const firestoreKeys = Object.freeze({
  ADMINS: 'admins',
  USERS: 'users',
  OWNERS: 'owners',
  MAIL: 'mail',
  HOMES: 'homes',
  STAYS: 'stays',
  ASSETS: 'assets',
});

export const toastSeverity = Object.freeze({
  ERROR: 'error',
  WARNING: 'warning',
  INFO: 'info',
  SUCCESS: 'success',
});

export const firebaseErrorCodes = Object.freeze({
  EMAIL_IN_USER: 'auth/email-already-in-use',
});

export const USER_LIST_COLUMNS = [
  { id: 'name', label: 'userListColumns.name', align: 'left' },
  { id: 'company', label: 'userListColumns.company', align: 'left' },
  { id: 'position', label: 'userListColumns.position', align: 'left' },
  { id: 'province', label: 'userListColumns.province', align: 'left' },
  { id: 'dateJoined', label: 'userListColumns.dateJoined', align: 'left' },
  { id: 'isFeatured', label: 'userListColumns.isFeatured', align: 'left' },
];

export const STAYS_COLUMNS = Object.freeze([
  { id: 'home', label: 'staysColumns.home' },
  { id: 'arrivalDate', label: 'staysColumns.arrivalDate' },
  { id: 'departureDate', label: 'staysColumns.departureDate' },
  { id: 'totalNights', label: 'staysColumns.totalNights' },
  { id: 'stayType', label: 'staysColumns.stayType' },
  { id: '', label: 'staysColumns.view', align: 'right' },
]);

export const AUTH_LANGUAGE_OPTIONS = Object.freeze([
  { value: 'english', label: 'ENG', locale: 'en' },
  { value: 'french', label: 'FRE', locale: 'fr' },
  { value: 'german', label: 'GER', locale: 'de' },
]);

export const LANGUAGE_OPTIONS = Object.freeze([
  { value: 'english', label: 'English', locale: 'en' },
  { value: 'french', label: 'Français', locale: 'fr' },
  { value: 'german', label: 'Deutsch', locale: 'de' },
]);

export const GENDER_OPTIONS = Object.freeze([
  { value: 'male', label: 'genderOptions.male' },
  { value: 'female', label: 'genderOptions.female' },
  { value: 'other', label: 'genderOptions.other' },
  { value: 'preferNotToAnswer', label: 'genderOptions.preferNotToAnswer' },
]);

export const STAY_STATUSES = Object.freeze({
  pending: 'pending',
  approved: 'approved',
  cancelled: 'cancelled',
  rejected: 'rejected',
});

export const STAY_TYPES = Object.freeze({
  coowner: 'coowner',
  guest: 'guest',
  rental: 'rental',
});

export const REQUEST_STATUSES = Object.freeze({
  pending: 'pending',
  approved: 'approved',
  denied: 'denied',
  none: '',
});

export const DAYS_OF_WEEKS = Object.freeze({
  Sunday: 0,
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
});

export const COUNTRY_DATA = Object.freeze([
  { country: 'South Africa', code: '27', iso: 'ZA', nationality: 'South African' },
  { country: 'Afghanistan', code: '93', iso: 'AF', nationality: 'Afghan' },
  { country: 'Albania', code: '355', iso: 'AL', nationality: 'Albanian' },
  { country: 'Algeria', code: '213', iso: 'DZ', nationality: 'Algerian' },
  { country: 'American Samoa', code: '1-684', iso: 'AS', nationality: 'American Samoan' },
  { country: 'Andorra', code: '376', iso: 'AD', nationality: 'Andorran' },
  { country: 'Angola', code: '244', iso: 'AO', nationality: 'Angolan' },
  { country: 'Anguilla', code: '1-264', iso: 'AI', nationality: 'Anguillan' },
  { country: 'Antarctica', code: '672', iso: 'AQ', nationality: 'Antarctic' },
  { country: 'Antigua and Barbuda', code: '1-268', iso: 'AG', nationality: 'Antiguan or Barbudan' },
  { country: 'Argentina', code: '54', iso: 'AR', nationality: 'Argentine' },
  { country: 'Armenia', code: '374', iso: 'AM', nationality: 'Armenian' },
  { country: 'Aruba', code: '297', iso: 'AW', nationality: 'Aruban' },
  { country: 'Australia', code: '61', iso: 'AU', nationality: 'Australian' },
  { country: 'Austria', code: '43', iso: 'AT', nationality: 'Austrian' },
  { country: 'Azerbaijan', code: '994', iso: 'AZ', nationality: 'Azerbaijani' },
  { country: 'Bahamas', code: '1-242', iso: 'BS', nationality: 'Bahamian' },
  { country: 'Bahrain', code: '973', iso: 'BH', nationality: 'Bahraini' },
  { country: 'Bangladesh', code: '880', iso: 'BD', nationality: 'Bangladeshi' },
  { country: 'Barbados', code: '1-246', iso: 'BB', nationality: 'Barbadian' },
  { country: 'Belarus', code: '375', iso: 'BY', nationality: 'Belarusian' },
  { country: 'Belgium', code: '32', iso: 'BE', nationality: 'Belgian' },
  { country: 'Belize', code: '501', iso: 'BZ', nationality: 'Belizean' },
  { country: 'Benin', code: '229', iso: 'BJ', nationality: 'Beninese' },
  { country: 'Bermuda', code: '1-441', iso: 'BM', nationality: 'Bermudian' },
  { country: 'Bhutan', code: '975', iso: 'BT', nationality: 'Bhutanese' },
  { country: 'Bolivia', code: '591', iso: 'BO', nationality: 'Bolivian' },
  { country: 'Bosnia and Herzegovina', code: '387', iso: 'BA', nationality: 'Bosnian or Herzegovinian' },
  { country: 'Botswana', code: '267', iso: 'BW', nationality: 'Botswanan' },
  { country: 'Brazil', code: '55', iso: 'BR', nationality: 'Brazilian' },
  { country: 'British Indian Ocean Territory', code: '246', iso: 'IO', nationality: 'BIOT' },
  { country: 'British Virgin Islands', code: '1-284', iso: 'VG', nationality: 'British Virgin Islander' },
  { country: 'Brunei', code: '673', iso: 'BN', nationality: 'Bruneian' },
  { country: 'Bulgaria', code: '359', iso: 'BG', nationality: 'Bulgarian' },
  { country: 'Burkina Faso', code: '226', iso: 'BF', nationality: 'Burkinabe' },
  { country: 'Burundi', code: '257', iso: 'BI', nationality: 'Burundian' },
  { country: 'Cambodia', code: '855', iso: 'KH', nationality: 'Cambodian' },
  { country: 'Cameroon', code: '237', iso: 'CM', nationality: 'Cameroonian' },
  { country: 'Canada', code: '1', iso: 'CA', nationality: 'Canadian' },
  { country: 'Cape Verde', code: '238', iso: 'CV', nationality: 'Cape Verdean' },
  { country: 'Cayman Islands', code: '1-345', iso: 'KY', nationality: 'Caymanian' },
  { country: 'Central African Republic', code: '236', iso: 'CF', nationality: 'Central African' },
  { country: 'Chad', code: '235', iso: 'TD', nationality: 'Chadian' },
  { country: 'Chile', code: '56', iso: 'CL', nationality: 'Chilean' },
  { country: 'China', code: '86', iso: 'CN', nationality: 'Chinese' },
  { country: 'Christmas Island', code: '61', iso: 'CX', nationality: 'Christmas Islander' },
  { country: 'Cocos Islands', code: '61', iso: 'CC', nationality: 'Cocos Islander' },
  { country: 'Colombia', code: '57', iso: 'CO', nationality: 'Colombian' },
  { country: 'Comoros', code: '269', iso: 'KM', nationality: 'Comoran' },
  { country: 'Cook Islands', code: '682', iso: 'CK', nationality: 'Cook Islander' },
  { country: 'Costa Rica', code: '506', iso: 'CR', nationality: 'Costa Rican' },
  { country: 'Croatia', code: '385', iso: 'HR', nationality: 'Croatian' },
  { country: 'Cuba', code: '53', iso: 'CU', nationality: 'Cuban' },
  { country: 'Curacao', code: '599', iso: 'CW', nationality: 'Curacaoan' },
  { country: 'Cyprus', code: '357', iso: 'CY', nationality: 'Cypriot' },
  { country: 'Czech Republic', code: '420', iso: 'CZ', nationality: 'Czech' },
  { country: 'Democratic Republic of the Congo', code: '243', iso: 'CD', nationality: 'Congolese' },
  { country: 'Denmark', code: '45', iso: 'DK', nationality: 'Danish' },
  { country: 'Djibouti', code: '253', iso: 'DJ', nationality: 'Djiboutian' },
  { country: 'Dominica', code: '1-767', iso: 'DM', nationality: 'Dominican' },
  { country: 'Dominican Republic', code: '1-809, 1-829, 1-849', iso: 'DO', nationality: 'Dominican' },
  { country: 'East Timor', code: '670', iso: 'TL', nationality: 'Timorese' },
  { country: 'Ecuador', code: '593', iso: 'EC', nationality: 'Ecuadorian' },
  { country: 'Egypt', code: '20', iso: 'EG', nationality: 'Egyptian' },
  { country: 'El Salvador', code: '503', iso: 'SV', nationality: 'Salvadoran' },
  { country: 'Equatorial Guinea', code: '240', iso: 'GQ', nationality: 'Equatorial Guinean' },
  { country: 'Eritrea', code: '291', iso: 'ER', nationality: 'Eritrean' },
  { country: 'Estonia', code: '372', iso: 'EE', nationality: 'Estonian' },
  { country: 'Ethiopia', code: '251', iso: 'ET', nationality: 'Ethiopian' },
  { country: 'Falkland Islands', code: '500', iso: 'FK', nationality: 'Falkland Islander' },
  { country: 'Faroe Islands', code: '298', iso: 'FO', nationality: 'Faroese' },
  { country: 'Fiji', code: '679', iso: 'FJ', nationality: 'Fijian' },
  { country: 'Finland', code: '358', iso: 'FI', nationality: 'Finnish' },
  { country: 'France', code: '33', iso: 'FR', nationality: 'French' },
  { country: 'French Polynesia', code: '689', iso: 'PF', nationality: 'French Polynesian' },
  { country: 'Gabon', code: '241', iso: 'GA', nationality: 'Gabonese' },
  { country: 'Gambia', code: '220', iso: 'GM', nationality: 'Gambian' },
  { country: 'Georgia', code: '995', iso: 'GE', nationality: 'Georgian' },
  { country: 'Germany', code: '49', iso: 'DE', nationality: 'German' },
  { country: 'Ghana', code: '233', iso: 'GH', nationality: 'Ghanaian' },
  { country: 'Gibraltar', code: '350', iso: 'GI', nationality: 'Gibraltarian' },
  { country: 'Greece', code: '30', iso: 'GR', nationality: 'Greek' },
  { country: 'Greenland', code: '299', iso: 'GL', nationality: 'Greenlandic' },
  { country: 'Grenada', code: '1-473', iso: 'GD', nationality: 'Grenadian' },
  { country: 'Guam', code: '1-671', iso: 'GU', nationality: 'Guamanian' },
  { country: 'Guatemala', code: '502', iso: 'GT', nationality: 'Guatemalan' },
  { country: 'Guernsey', code: '44-1481', iso: 'GG', nationality: 'Guernsey' },
  { country: 'Guinea', code: '224', iso: 'GN', nationality: 'Guinean' },
  { country: 'Guinea-Bissau', code: '245', iso: 'GW', nationality: 'Bissau-Guinean' },
  { country: 'Guyana', code: '592', iso: 'GY', nationality: 'Guyanese' },
  { country: 'Haiti', code: '509', iso: 'HT', nationality: 'Haitian' },
  { country: 'Honduras', code: '504', iso: 'HN', nationality: 'Honduran' },
  { country: 'Hong Kong', code: '852', iso: 'HK', nationality: 'Hong Konger' },
  { country: 'Hungary', code: '36', iso: 'HU', nationality: 'Hungarian' },
  { country: 'Iceland', code: '354', iso: 'IS', nationality: 'Icelandic' },
  { country: 'India', code: '91', iso: 'IN', nationality: 'Indian' },
  { country: 'Indonesia', code: '62', iso: 'ID', nationality: 'Indonesian' },
  { country: 'Iran', code: '98', iso: 'IR', nationality: 'Iranian' },
  { country: 'Iraq', code: '964', iso: 'IQ', nationality: 'Iraqi' },
  { country: 'Ireland', code: '353', iso: 'IE', nationality: 'Irish' },
  { country: 'Isle of Man', code: '44-1624', iso: 'IM', nationality: 'Manx' },
  { country: 'Israel', code: '972', iso: 'IL', nationality: 'Israeli' },
  { country: 'Italy', code: '39', iso: 'IT', nationality: 'Italian' },
  { country: 'Ivory Coast', code: '225', iso: 'CI', nationality: 'Ivorian' },
  { country: 'Jamaica', code: '1-876', iso: 'JM', nationality: 'Jamaican' },
  { country: 'Japan', code: '81', iso: 'JP', nationality: 'Japanese' },
  { country: 'Jersey', code: '44-1534', iso: 'JE', nationality: 'Jersey' },
  { country: 'Jordan', code: '962', iso: 'JO', nationality: 'Jordanian' },
  { country: 'Kazakhstan', code: '7', iso: 'KZ', nationality: 'Kazakhstani' },
  { country: 'Kenya', code: '254', iso: 'KE', nationality: 'Kenyan' },
  { country: 'Kiribati', code: '686', iso: 'KI', nationality: 'I-Kiribati' },
  { country: 'Kosovo', code: '383', iso: 'XK', nationality: 'Kosovar' },
  { country: 'Kuwait', code: '965', iso: 'KW', nationality: 'Kuwaiti' },
  { country: 'Kyrgyzstan', code: '996', iso: 'KG', nationality: 'Kyrgyzstani' },
  { country: 'Laos', code: '856', iso: 'LA', nationality: 'Lao' },
  { country: 'Latvia', code: '371', iso: 'LV', nationality: 'Latvian' },
  { country: 'Lebanon', code: '961', iso: 'LB', nationality: 'Lebanese' },
  { country: 'Lesotho', code: '266', iso: 'LS', nationality: 'Basotho' },
  { country: 'Liberia', code: '231', iso: 'LR', nationality: 'Liberian' },
  { country: 'Libya', code: '218', iso: 'LY', nationality: 'Libyan' },
  { country: 'Liechtenstein', code: '423', iso: 'LI', nationality: 'Liechtensteiner' },
  { country: 'Lithuania', code: '370', iso: 'LT', nationality: 'Lithuanian' },
  { country: 'Luxembourg', code: '352', iso: 'LU', nationality: 'Luxembourgish' },
  { country: 'Macau', code: '853', iso: 'MO', nationality: 'Macanese' },
  { country: 'Madagascar', code: '261', iso: 'MG', nationality: 'Malagasy' },
  { country: 'Malawi', code: '265', iso: 'MW', nationality: 'Malawian' },
  { country: 'Malaysia', code: '60', iso: 'MY', nationality: 'Malaysian' },
  { country: 'Maldives', code: '960', iso: 'MV', nationality: 'Maldivian' },
  { country: 'Mali', code: '223', iso: 'ML', nationality: 'Malian' },
  { country: 'Malta', code: '356', iso: 'MT', nationality: 'Maltese' },
  { country: 'Marshall Islands', code: '692', iso: 'MH', nationality: 'Marshallese' },
  { country: 'Mauritania', code: '222', iso: 'MR', nationality: 'Mauritanian' },
  { country: 'Mauritius', code: '230', iso: 'MU', nationality: 'Mauritian' },
  { country: 'Mayotte', code: '262', iso: 'YT', nationality: 'Mahoran' },
  { country: 'Mexico', code: '52', iso: 'MX', nationality: 'Mexican' },
  { country: 'Micronesia', code: '691', iso: 'FM', nationality: 'Micronesian' },
  { country: 'Moldova', code: '373', iso: 'MD', nationality: 'Moldovan' },
  { country: 'Monaco', code: '377', iso: 'MC', nationality: 'Monacan' },
  { country: 'Mongolia', code: '976', iso: 'MN', nationality: 'Mongolian' },
  { country: 'Montenegro', code: '382', iso: 'ME', nationality: 'Montenegrin' },
  { country: 'Montserrat', code: '1-664', iso: 'MS', nationality: 'Montserratian' },
  { country: 'Morocco', code: '212', iso: 'MA', nationality: 'Moroccan' },
  { country: 'Mozambique', code: '258', iso: 'MZ', nationality: 'Mozambican' },
  { country: 'Myanmar', code: '95', iso: 'MM', nationality: 'Burmese' },
  { country: 'Namibia', code: '264', iso: 'NA', nationality: 'Namibian' },
  { country: 'Nauru', code: '674', iso: 'NR', nationality: 'Nauruan' },
  { country: 'Nepal', code: '977', iso: 'NP', nationality: 'Nepali' },
  { country: 'Netherlands', code: '31', iso: 'NL', nationality: 'Dutch' },
  { country: 'Netherlands Antilles', code: '599', iso: 'AN', nationality: 'Dutch Antillean' },
  { country: 'New Caledonia', code: '687', iso: 'NC', nationality: 'New Caledonian' },
  { country: 'New Zealand', code: '64', iso: 'NZ', nationality: 'New Zealander' },
  { country: 'Nicaragua', code: '505', iso: 'NI', nationality: 'Nicaraguan' },
  { country: 'Niger', code: '227', iso: 'NE', nationality: 'Nigerien' },
  { country: 'Nigeria', code: '234', iso: 'NG', nationality: 'Nigerian' },
  { country: 'Niue', code: '683', iso: 'NU', nationality: 'Niuean' },
  { country: 'North Korea', code: '850', iso: 'KP', nationality: 'North Korean' },
  { country: 'North Macedonia', code: '389', iso: 'MK', nationality: 'Macedonian' },
  { country: 'Northern Mariana Islands', code: '1-670', iso: 'MP', nationality: 'Northern Mariana Islander' },
  { country: 'Norway', code: '47', iso: 'NO', nationality: 'Norwegian' },
  { country: 'Oman', code: '968', iso: 'OM', nationality: 'Omani' },
  { country: 'Pakistan', code: '92', iso: 'PK', nationality: 'Pakistani' },
  { country: 'Palau', code: '680', iso: 'PW', nationality: 'Palauan' },
  { country: 'Palestine', code: '970', iso: 'PS', nationality: 'Palestinian' },
  { country: 'Panama', code: '507', iso: 'PA', nationality: 'Panamanian' },
  { country: 'Papua New Guinea', code: '675', iso: 'PG', nationality: 'Papua New Guinean' },
  { country: 'Paraguay', code: '595', iso: 'PY', nationality: 'Paraguayan' },
  { country: 'Peru', code: '51', iso: 'PE', nationality: 'Peruvian' },
  { country: 'Philippines', code: '63', iso: 'PH', nationality: 'Filipino' },
  { country: 'Pitcairn', code: '64', iso: 'PN', nationality: 'Pitcairn Islander' },
  { country: 'Poland', code: '48', iso: 'PL', nationality: 'Polish' },
  { country: 'Portugal', code: '351', iso: 'PT', nationality: 'Portuguese' },
  { country: 'Puerto Rico', code: '1-787, 1-939', iso: 'PR', nationality: 'Puerto Rican' },
  { country: 'Qatar', code: '974', iso: 'QA', nationality: 'Qatari' },
  { country: 'Republic of the Congo', code: '242', iso: 'CG', nationality: 'Congolese' },
  { country: 'Reunion', code: '262', iso: 'RE', nationality: 'Reunionese' },
  { country: 'Romania', code: '40', iso: 'RO', nationality: 'Romanian' },
  { country: 'Russia', code: '7', iso: 'RU', nationality: 'Russian' },
  { country: 'Rwanda', code: '250', iso: 'RW', nationality: 'Rwandan' },
  { country: 'Saint Barthelemy', code: '590', iso: 'BL', nationality: 'Saint Barthelemy' },
  { country: 'Saint Helena', code: '290', iso: 'SH', nationality: 'Saint Helenian' },
  { country: 'Saint Kitts and Nevis', code: '1-869', iso: 'KN', nationality: 'Kittitian or Nevisian' },
  { country: 'Saint Lucia', code: '1-758', iso: 'LC', nationality: 'Saint Lucian' },
  { country: 'Saint Martin', code: '590', iso: 'MF', nationality: 'Saint Martin' },
  { country: 'Saint Pierre and Miquelon', code: '508', iso: 'PM', nationality: 'Saint-Pierrais or Miquelonnais' },
  { country: 'Saint Vincent and the Grenadines', code: '1-784', iso: 'VC', nationality: 'Saint Vincentian' },
  { country: 'Samoa', code: '685', iso: 'WS', nationality: 'Samoan' },
  { country: 'San Marino', code: '378', iso: 'SM', nationality: 'Sammarinese' },
  { country: 'Sao Tome and Principe', code: '239', iso: 'ST', nationality: 'Sao Tomean' },
  { country: 'Saudi Arabia', code: '966', iso: 'SA', nationality: 'Saudi Arabian' },
  { country: 'Senegal', code: '221', iso: 'SN', nationality: 'Senegalese' },
  { country: 'Serbia', code: '381', iso: 'RS', nationality: 'Serbian' },
  { country: 'Seychelles', code: '248', iso: 'SC', nationality: 'Seychellois' },
  { country: 'Sierra Leone', code: '232', iso: 'SL', nationality: 'Sierra Leonean' },
  { country: 'Singapore', code: '65', iso: 'SG', nationality: 'Singaporean' },
  { country: 'Sint Maarten', code: '1-721', iso: 'SX', nationality: 'Sint Maarten' },
  { country: 'Slovakia', code: '421', iso: 'SK', nationality: 'Slovak' },
  { country: 'Slovenia', code: '386', iso: 'SI', nationality: 'Slovene' },
  { country: 'Solomon Islands', code: '677', iso: 'SB', nationality: 'Solomon Islander' },
  { country: 'Somalia', code: '252', iso: 'SO', nationality: 'Somali' },
  { country: 'South Africa', code: '27', iso: 'ZA', nationality: 'South African' },
  { country: 'South Korea', code: '82', iso: 'KR', nationality: 'South Korean' },
  { country: 'South Sudan', code: '211', iso: 'SS', nationality: 'South Sudanese' },
  { country: 'Spain', code: '34', iso: 'ES', nationality: 'Spanish' },
  { country: 'Sri Lanka', code: '94', iso: 'LK', nationality: 'Sri Lankan' },
  { country: 'Sudan', code: '249', iso: 'SD', nationality: 'Sudanese' },
  { country: 'Suriname', code: '597', iso: 'SR', nationality: 'Surinamese' },
  { country: 'Svalbard and Jan Mayen', code: '47', iso: 'SJ', nationality: 'Svalbard' },
  { country: 'Swaziland', code: '268', iso: 'SZ', nationality: 'Swazi' },
  { country: 'Sweden', code: '46', iso: 'SE', nationality: 'Swedish' },
  { country: 'Switzerland', code: '41', iso: 'CH', nationality: 'Swiss' },
  { country: 'Syria', code: '963', iso: 'SY', nationality: 'Syrian' },
  { country: 'Taiwan', code: '886', iso: 'TW', nationality: 'Taiwanese' },
  { country: 'Tajikistan', code: '992', iso: 'TJ', nationality: 'Tajikistani' },
  { country: 'Tanzania', code: '255', iso: 'TZ', nationality: 'Tanzanian' },
  { country: 'Thailand', code: '66', iso: 'TH', nationality: 'Thai' },
  { country: 'Togo', code: '228', iso: 'TG', nationality: 'Togolese' },
  { country: 'Tokelau', code: '690', iso: 'TK', nationality: 'Tokelauan' },
  { country: 'Tonga', code: '676', iso: 'TO', nationality: 'Tongan' },
  { country: 'Trinidad and Tobago', code: '1-868', iso: 'TT', nationality: 'Trinidadian or Tobagonian' },
  { country: 'Tunisia', code: '216', iso: 'TN', nationality: 'Tunisian' },
  { country: 'Turkey', code: '90', iso: 'TR', nationality: 'Turkish' },
  { country: 'Turkmenistan', code: '993', iso: 'TM', nationality: 'Turkmen' },
  { country: 'Turks and Caicos Islands', code: '1-649', iso: 'TC', nationality: 'Turks and Caicos Islander' },
  { country: 'Tuvalu', code: '688', iso: 'TV', nationality: 'Tuvaluan' },
  { country: 'Uganda', code: '256', iso: 'UG', nationality: 'Ugandan' },
  { country: 'Ukraine', code: '380', iso: 'UA', nationality: 'Ukrainian' },
  { country: 'United Arab Emirates', code: '971', iso: 'AE', nationality: 'Emirati' },
  { country: 'United Kingdom', code: '44', iso: 'GB', nationality: 'British' },
  { country: 'United States', code: '1', iso: 'US', nationality: 'American' },
  { country: 'Uruguay', code: '598', iso: 'UY', nationality: 'Uruguayan' },
  { country: 'Uzbekistan', code: '998', iso: 'UZ', nationality: 'Uzbekistani' },
  { country: 'Vanuatu', code: '678', iso: 'VU', nationality: 'Ni-Vanuatu' },
  { country: 'Vatican', code: '379', iso: 'VA', nationality: 'Vatican' },
  { country: 'Venezuela', code: '58', iso: 'VE', nationality: 'Venezuelan' },
  { country: 'Vietnam', code: '84', iso: 'VN', nationality: 'Vietnamese' },
  { country: 'Wallis and Futuna', code: '681', iso: 'WF', nationality: 'Wallis and Futuna' },
  { country: 'Western Sahara', code: '212', iso: 'EH', nationality: 'Sahrawi' },
  { country: 'Yemen', code: '967', iso: 'YE', nationality: 'Yemeni' },
  { country: 'Zambia', code: '260', iso: 'ZM', nationality: 'Zambian' },
  { country: 'Zimbabwe', code: '263', iso: 'ZW', nationality: 'Zimbabwean' },
]);
