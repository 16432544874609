import { db } from './firebase';
import { firestoreKeys } from '../utils/constants';

export default function useHomeService() {
  const getUserHomes = async (userId) => {
    const allHomes = await db.collection(firestoreKeys.HOMES).get();
    return findUserHomes(allHomes, userId);
  };

  async function updateHome(homeId, data) {
    await db.collection(firestoreKeys.HOMES).doc(homeId).update(data);
  }

  async function findUserHomes(allHomes, userId) {
    const userHomes = [];
    await allHomes.docs?.forEach((home) => {
      if (home.data().owners?.find((owner) => owner.id === userId)) {
        userHomes.push({ ...home.data(), id: home.id });
      }
    });
    return userHomes;
  }

  async function getHomeById(homeId) {
    const home = await db.collection(firestoreKeys.HOMES).doc(homeId).get();
    return home.data();
  }

  return { getUserHomes, getHomeById, updateHome };
}
