const incrementReferenceNumber = (refNumber) => {
  let number = parseInt(refNumber, 10);

  number += 1;

  const newRefNumber = number.toString().padStart(refNumber?.length, '0');

  return newRefNumber;
};

export default incrementReferenceNumber;
