import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import DashboardLayout from '../../app/layouts/dashboard';
import LogoOnlyLayout from '../../app/layouts/LogoOnlyLayout';
// guards
import AuthGuard from '../../app/guards/AuthGuard';
import { PATH_AFTER_LOGIN } from '../../app/config';
// components
import { LoadingScreen } from '../components/base';
import { PATH_AUTH, PATH_DASHBOARD, PATH_MY_HOME, PATH_RULES, PATH_SETTINGS } from './paths';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: PATH_AUTH.root,
      element: <DashboardLayout />,
      children: [
        {
          path: '',
          element: <Login />,
        },
        {
          path: 'login',
          element: <Login />,
        },
        {
          path: 'reset-password',
          element: <ResetPassword />,
        },
        {
          path: 'set-password',
          element: (
            <AuthGuard>
              <SetPassword />
            </AuthGuard>
          ),
        },
      ],
    },

    // Dashboard Routes
    {
      path: PATH_DASHBOARD.root,
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'app', element: <Dashboard /> },
        { path: 'schedule/:id', element: <GeneralScheduling /> },
        { path: 'first-schedule/:id', element: <FirstScheduling /> },
        { path: 'details/:id', element: <StayDetails /> },
      ],
    },

    // My Home Routes
    {
      path: PATH_MY_HOME.root,
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [{ path: '', element: <MyHome /> }],
    },

    // Settings Routes

    {
      path: PATH_SETTINGS.root,
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: PATH_SETTINGS.myProfile,
          element: <MyProfile />,
        },
      ],
    },

    // Rules Routes

    {
      path: PATH_RULES.root,
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: PATH_RULES.scheduling,
          element: <SchedulingRules />,
        },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [{ element: <Dashboard />, index: true }],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../../modules/auth/Login')));
const ResetPassword = Loadable(lazy(() => import('../../modules/auth/ResetPassword')));
const SetPassword = Loadable(lazy(() => import('../../modules/auth/SetPassword')));

// GENERAL
const Dashboard = Loadable(lazy(() => import('../../modules/dashboard/Dashboard')));
const GeneralScheduling = Loadable(lazy(() => import('../../modules/dashboard/Scheduling')));
const FirstScheduling = Loadable(lazy(() => import('../../modules/dashboard/Scheduling/FirstScheduling')));
const StayDetails = Loadable(lazy(() => import('../../modules/dashboard/StayDetails/StayDetails')));

// SCHEDULE

// MY HOME
const MyHome = Loadable(lazy(() => import('../../modules/myHome/MyHome')));

// SETTINGS
const MyProfile = Loadable(lazy(() => import('../../modules/settings/MyProfile')));

// RULES
const SchedulingRules = Loadable(lazy(() => import('../../modules/rules/SchedulingRules')));

// MAIN
const Page500 = Loadable(lazy(() => import('../../modules/auth/Page500')));
const Page403 = Loadable(lazy(() => import('../../modules/auth/Page403')));
const Page404 = Loadable(lazy(() => import('../../modules/auth/Page404')));
