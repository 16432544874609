import PropTypes from 'prop-types';
// @mui
import { Box, IconButton, styled, Typography, useTheme } from '@mui/material';
import { East, West } from '@mui/icons-material';
// components
import { Image } from '.';

// ----------------------------------------------------------------------

const BoxStyle = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  paddingTop: theme.spacing(2),
  gap: theme.spacing(4),
}));

// ----------------------------------------------------------------------

HouseSelector.propTypes = {
  home: PropTypes.object,
  setSelectedHome: PropTypes.func,
  homes: PropTypes.array,
};

export default function HouseSelector({ home, homes, setSelectedHome }) {
  const theme = useTheme();
  const multipleHomes = homes.length > 1;

  const handleLeftButton = () => {
    const index = homes.indexOf(home);
    if (index === 0) {
      setSelectedHome(homes[homes.length - 1]);
    } else {
      setSelectedHome(homes[index - 1]);
    }
  };

  const handleRightButton = () => {
    const index = homes.indexOf(home);
    if (index === homes.length - 1) {
      setSelectedHome(homes[0]);
    } else {
      setSelectedHome(homes[index + 1]);
    }
  };

  return (
    <BoxStyle>
      {multipleHomes && (
        <IconButton onClick={handleLeftButton}>
          <West color="disabled" />
        </IconButton>
      )}
      <Image src={home?.photos[0]} sx={{ width: 40, height: 40, borderRadius: '2px' }} />
      <Typography
        variant="h5"
        sx={{ fontFamily: 'Roboto', fontWeight: 400, fontSize: 20, color: theme.palette.primary.main }}
      >
        {home?.location?.town === '' ? home?.location?.region : home?.location?.town}
      </Typography>
      {multipleHomes && (
        <IconButton onClick={handleRightButton}>
          <East color="disabled" />
        </IconButton>
      )}
    </BoxStyle>
  );
}
