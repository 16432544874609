import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, Container, IconButton, Typography } from '@mui/material';
import { ArrowBackRounded } from '@mui/icons-material';
// ----------------------------------------------------------------------

PageTitle.propTypes = {
  route: PropTypes.string,
  hasButton: PropTypes.bool,
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

export default function PageTitle({ route, hasButton = false, title, subtitle }) {
  return (
    <Container sx={{ display: 'flex', minWidth: '100%', mt: '1.5rem' }}>
      {hasButton && (
        <IconButton color="primary" component={RouterLink} to={route}>
          <ArrowBackRounded />
        </IconButton>
      )}
      <Box sx={{ ml: '1rem' }}>
        <Typography variant="pageTitle" gutterBottom>
          {title}
        </Typography>
        <Typography variant="subtitle1">{subtitle}</Typography>
      </Box>
    </Container>
  );
}
