import { db, storage } from './firebase';
import { firestoreKeys } from '../utils/constants';

export default function useUserService() {
  const updateUser = (userId, data) => db.collection(firestoreKeys.OWNERS).doc(userId).update(data);

  const getUserById = (userId) =>
    db
      .collection(firestoreKeys.OWNERS)
      .doc(userId)
      .get()
      .then((res) => res.data());

  const addUserImage = (docId, image) => {
    const ref = storage.ref(`${firestoreKeys.OWNERS}/${docId}/${image.name}`);
    return ref.put(image).then(() => ref.getDownloadURL());
  };

  const removeExistingUserImage = (docId, imageUrl) => {
    const existingImageRef = storage.refFromURL(imageUrl);
    existingImageRef.delete();
  };

  const getAssociatedUsers = (userId) => db.collection(firestoreKeys.OWNERS).where('mainUserId', '==', userId).get();

  return { updateUser, getUserById, addUserImage, removeExistingUserImage, getAssociatedUsers };
}
