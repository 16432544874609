// @mui
import { styled } from '@mui/material/styles';
import { Button, Popover } from '@mui/material';
// config
import { NAVBAR } from '../../../../app/config';

// ----------------------------------------------------------------------

export const ListItemStyle = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'activeRoot' && prop !== 'activeSub' && prop !== 'subItem' && prop !== 'open',
})(({ activeRoot, activeSub, subItem, open, theme }) => ({
  fontFamily: 'Roboto, sans-serif',
  backgroundColor: 'transparent',
  ...theme.typography.navItem,
  marginRight: theme.spacing(5),
  color: '#fff',
  height: NAVBAR.DASHBOARD_ITEM_HORIZONTAL_HEIGHT,
  // activeRoot
  ...(activeRoot && {
    ...theme.typography.navItem,
    fontWeight: 500,
    borderBottom: `3px solid white`,
  }),
  // activeSub
  ...(activeSub && {
    ...theme.typography.navItem,
    fontWeight: 500,
    color: theme.palette.text.primary,
  }),
  // subItem
  ...(subItem && {
    width: '100%',
    margin: 0,
    paddingRight: 0,
    paddingLeft: theme.spacing(1),
    justifyContent: 'space-between',
  }),
  // open
  ...(open &&
    !activeRoot && {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.action.hover,
  }),
}));

// ----------------------------------------------------------------------

export const PaperStyle = styled(Popover)(({ theme }) => ({
  pointerEvents: 'none',
  '& .MuiPopover-paper': {
    width: 160,
    pointerEvents: 'auto',
    padding: theme.spacing(1),
    borderRadius: Number(theme.shape.borderRadius) * 1.5,
    boxShadow: theme.customShadows.dropdown,
  },
}));
