// routes
import { Image } from '../../../../shared/components/base';
import { PATH_DASHBOARD, PATH_MY_HOME, PATH_SETTINGS } from '../../../../shared/routes/paths';
// assets
import Home from '../../../../assets/images/icons/home.png';
import Profile from '../../../../assets/images/icons/profile.png';
import Calendar from '../../../../assets/images/icons/calendar.png';

// ----------------------------------------------------------------------

const navConfig = [
  {
    title: 'navigation.myStays',
    items: [
      {
        title: 'navigation.myStays',
        path: PATH_DASHBOARD.root,
        icon: <Image src={Calendar} alt="stays" />,
      },
    ],
  },
  {
    title: 'navigation.myHome',
    items: [
      {
        title: 'navigation.myHome',
        path: PATH_MY_HOME.root,
        icon: <Image src={Home} alt="home" />,
      },
    ],
  },
  {
    title: 'navigation.myProfile',
    items: [
      {
        title: 'navigation.myProfile',
        path: PATH_SETTINGS.myProfile,
        icon: <Image src={Profile} alt="profile" />,
      },
    ],
  },
];

export default navConfig;
