import { db } from './firebase';
import { firestoreKeys } from '../utils/constants';

export default function useStayService() {
  const createStay = (data) => db.collection(firestoreKeys.STAYS).add(data);

  const getAllStaysByHome = async (homeId) => {
    const stays = await db.collection(firestoreKeys.STAYS).where('details.homeId', '==', homeId).get();
    return stays.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
  };

  const getAllStaysByOwner = async (userId) => {
    const stays = await db.collection(firestoreKeys.STAYS).where('createdByUserId', '==', userId).get();
    return stays.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
  };

  const getStayById = async (stayId) => {
    const stay = await db.collection(firestoreKeys.STAYS).doc(stayId).get();
    const { homeId } = stay.data().details;
    const { createdByUserId } = stay.data();

    const [homeDetails, userDetails] = await Promise.all([
      db.collection(firestoreKeys.HOMES).doc(homeId).get(),
      db.collection(firestoreKeys.OWNERS).doc(createdByUserId).get(),
    ]);

    return { ...stay.data(), id: stay.id, homeDetails: homeDetails.data(), bookedBy: userDetails.data() };
  };

  const updateStay = (stayId, data) => db.collection(firestoreKeys.STAYS).doc(stayId).update(data);

  const deleteStay = (stayId) => db.collection(firestoreKeys.STAYS).doc(stayId).delete();

  return { createStay, getAllStaysByHome, getAllStaysByOwner, getStayById, updateStay, deleteStay };
}
