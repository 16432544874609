import { Outlet, useLocation } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
// config
import { HEADER } from '../../config';
// components
import DashboardHeader from './header';

// ----------------------------------------------------------------------

const MainStyle = styled(
  'main',
  {}
)(({ theme }) => {
  const { pathname } = useLocation();
  const isAuth = pathname.startsWith('/auth/');
  const disablePadding = pathname.startsWith('/auth/') || pathname.startsWith('/dashboard/first-schedule');

  return {
    flexGrow: 1,
    paddingTop: !isAuth && HEADER.MOBILE_HEIGHT,
    paddingBottom: !disablePadding && HEADER.MOBILE_HEIGHT + 24,
    [theme.breakpoints.up('lg')]: {
      paddingTop: !isAuth && HEADER.DASHBOARD_DESKTOP_HEIGHT,
      paddingBottom: !disablePadding && HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    },
  };
});

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const { pathname } = useLocation();
  const isAuth = pathname.startsWith('/auth/');

  return (
    <Box
      sx={{
        display: { lg: 'flex' },
        minHeight: { lg: 1 },
      }}
    >
      <DashboardHeader isAuth={isAuth} />
      <MainStyle>
        <Outlet />
      </MainStyle>
    </Box>
  );
}
