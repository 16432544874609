// components
import { InputSelectIcon } from './CustomIcons';

// ----------------------------------------------------------------------

export default function Select() {
  return {
    MuiSelect: {
      defaultProps: {
        IconComponent: InputSelectIcon,
      },
      styleOverrides: {
        root: {
          borderRadius: 2,
        },
        // Outlined
        outlined: {
          color: '#fff',
        },
      },
    },
  };
}
