// i18n
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n/i18nConfig';
// routes
import Router from '../shared/routes';
// theme
import ThemeProvider from '../theme';
// components
import ThemeSettings from '../shared/components/settings';
import { ScrollToTop, ProgressBarStyle, Toast } from '../shared/components/base';
import MotionLazyContainer from '../shared/components/animate/MotionLazyContainer';

// ----------------------------------------------------------------------

export default function App() {
  return (
    <I18nextProvider i18n={i18n}>
      <MotionLazyContainer>
        <ThemeProvider>
          <ThemeSettings>
            <ProgressBarStyle />
            <ScrollToTop />
            <Router />
            <Toast />
          </ThemeSettings>
        </ThemeProvider>
      </MotionLazyContainer>
    </I18nextProvider>
  );
}
