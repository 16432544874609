// ----------------------------------------------------------------------

import { pxToRem } from '../../utils/getFontValue';

export default function Button(theme) {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.primary.light,
          fontWeight: 400,
          '&:hover': {
            boxShadow: 'none',
          },
          borderRadius: 2,
        },
        sizeLarge: {
          height: 48,
        },
        // contained
        containedInherit: {
          color: theme.palette.grey[800],
          boxShadow: theme.customShadows.z8,
          '&:hover': {
            backgroundColor: theme.palette.grey[400],
          },
        },
        containedPrimary: {
          '&:hover': {
            backgroundColor: theme.palette.primary.main,
          },
        },
        containedSecondary: {
          color: '#FFF',
          backgroundColor: '#A3A7AB',
          fontWeight: 500,
          fontSize: pxToRem(14),
          '&:hover': {
            backgroundColor: '#A3A7AB',
            color: '#FFF',
          },
        },
        containedTertiary: {
          fontFamily: theme.typography.secondaryButton.fontFamily,
          fontWeight: 400,
          fontSize: pxToRem(16),
          border: `1px solid ${theme.palette.primary.main}`,
          backgroundColor: theme.palette.primary.main,
          color: '#FFF',
          justifyContent: 'space-between',
          '&:hover': {
            backgroundColor: theme.palette.primary.light,
            color: '#FFF',
          },
        },
        containedInfo: {
          boxShadow: theme.customShadows.info,
        },
        containedSuccess: {
          boxShadow: theme.customShadows.success,
        },
        containedWarning: {
          boxShadow: theme.customShadows.warning,
        },
        containedError: {
          color: '#FFF',
          '&:hover': {
            backgroundColor: theme.palette.error.main,
            color: '#FFF',
          },
        },
        // outlined
        outlinedInherit: {
          border: `1px solid ${theme.palette.grey[500_32]}`,
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        },
        textInherit: {
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        },
        textPrimary: {
          color: '#A3A7AB',
          backgroundColor: 'transparent',
        },
        outlinedSecondary: {
          fontFamily: theme.typography.secondaryButton.fontFamily,
          color: theme.palette.primary.main,
          border: `1px solid ${theme.palette.primary.main}`,
          '&:hover': {
            backgroundColor: theme.palette.primary.main,
            color: '#FFF',
          },
        },
        outlinedTertiary: {
          fontFamily: theme.typography.secondaryButton.fontFamily,
          backgroundColor: theme.palette.common.white,
          fontWeight: 400,
          fontSize: pxToRem(16),
          border: `1px solid ${theme.palette.grey[500_32]}`,
          justifyContent: 'space-between',
          '&:hover': {
            backgroundColor: theme.palette.primary.light,
            color: '#FFF',
          },
        },
        outlinedWhite: {
          border: `1px solid ${theme.palette.grey[500_32]}`,
          backgroundColor: theme.palette.common.white,
          boxShadow: theme.customShadows.card,
          fontSize: pxToRem(16),
          '&:hover': {
            backgroundColor: theme.palette.primary.light,
          },
        },
        // borderless
        borderless: {
          color: theme.palette.primary.main,
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        },
        borderlessSecondary: {
          backgroundColor: theme.palette.common.white,
          color: theme.palette.primary.main,
          fontWeight: 500,
          fontSize: pxToRem(15),
        },
        navigatorActive: {
          backgroundColor: theme.palette.tertiary.main,
          borderBottom: '3px solid white',
          color: '#FFF',
          '&:hover': {
            backgroundColor: theme.palette.tertiary.main,
          },
        },
        navigatorInactive: {
          backgroundColor: theme.palette.tertiary.main,
          color: '#FFF',
          '&:hover': {
            backgroundColor: theme.palette.tertiary.main,
            textDecoration: 'underline',
            color: '#FFF',
          },
        },
        borderlessNav: {
          color: '#FFF',
          '&:hover': {
            backgroundColor: theme.palette.primary.light,
            color: '#FFF',
          },
        },
      },
    },
  };
}
