// i18n
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAtom } from 'jotai';
import { changeLanguage } from 'i18next';
// services
import { useAuthService, useUserService } from '../../../../shared/services';
// state
import { userRecordAtom } from '../../../../shared/state/atoms';
// hooks
import { useToast } from '../../../../shared/hooks';
// paths
import { PATH_AUTH, PATH_DASHBOARD } from '../../../../shared/routes/paths';
// constants
import { LANGUAGE_OPTIONS } from '../../../../shared/utils/constants';

function useLoginForm() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [, setUserRecord] = useAtom(userRecordAtom);
  const { signIn } = useAuthService();
  const { getUserById } = useUserService();
  const { showError } = useToast();

  const login = (email, password) =>
    signIn(email, password)
      .then((res) => {
        getUserById(res.user.uid)
          .then((user) => {
            setUserRecord({ ...user, id: res.user.uid });
            changeLanguage(LANGUAGE_OPTIONS.find((language) => language.value === user.details.language).locale);
            navigate(user.onboardingComplete ? PATH_DASHBOARD.general.app : PATH_AUTH.setPassword, { replace: true });
          })
          .catch((error) => showError(error.message));
      })
      .catch(() => showError(t('login.validation.incorrectPassword')));

  return { login };
}

export default useLoginForm;
